import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {getToken} from "../../shared/utils/authUtils";

const BASE_API_URL = process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : "https://skifryfpel-dev-apimngment.azure-api.net/";

const lobbyApiUrl = `SkyfriSidemApi/v1/LobbyScreen`

export const aggregateScreenApi = createApi({
    reducerPath: "aggregateScreenApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_API_URL}`,
        prepareHeaders: async (headers) => {
            const token = await getToken();
            headers.set("authorization", `Bearer ${token}`);
            headers.set(
                "Ocp-Apim-Subscription-Key",
                process.env.REACT_APP_API_MKEY
            );
            headers.set("Content-Type", "text/json");
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getPlantProfileInfoLobby: builder.query<any[], { portfolioId: string, plantId: string }>({
            query: ({ portfolioId, plantId }) => `${lobbyApiUrl}/GetClientInfo?portfolioName=${portfolioId}&plantId=${plantId}`,
            keepUnusedDataFor: 10800 // 3 hours
        }),
        getGenDataLobby: builder.query<any[], { portfolioId: string, plantId: string }>({
            query: ({ portfolioId, plantId }) => `${lobbyApiUrl}/GetGenData?portfolioName=${portfolioId}&plantId=${plantId}`,
            keepUnusedDataFor: 1800 // 30 minutes
        }),
        getGraphDataLobby: builder.query<any[], { portfolioId: string, plantId: string }>({
            query: ({ portfolioId, plantId }) => `${lobbyApiUrl}/GetGenDataMonthly?portfolioName=${portfolioId}&plantId=${plantId}`,
            keepUnusedDataFor: 1800 // 30 minutes
        }),
        getGenDataMonthlyEachPlant: builder.query<any, { fromDate: string, toDate: string, plantId }>({
            query: ({ fromDate, toDate, plantId }) => `${lobbyApiUrl}/GetGenDataMonthlyEachPlant?fromDate=${fromDate}&toDate=${toDate}&plantId=${plantId}`,
            keepUnusedDataFor: 1800 // 30 minutes
        }),
        getEnvironmentalData: builder.query<any, { fromDate: string, toDate: string, portfolioName: string, plantId: string, }>({
            query: ({ fromDate, toDate, portfolioName, plantId, }) => `${lobbyApiUrl}/GetCarbonEmissions?fromDate=${fromDate}&toDate=${toDate}&portfolioName=${portfolioName}&plantId=${plantId}`,
            keepUnusedDataFor: 1800 // 30 minutes
        }),
    }),
});
export const {
    useGetPlantProfileInfoLobbyQuery,
    useGetGenDataLobbyQuery,
    useGetGraphDataLobbyQuery,
    useGetGenDataMonthlyEachPlantQuery,
    useGetEnvironmentalDataQuery,
} = aggregateScreenApi;