import {Configuration, EventType, PublicClientApplication} from "@azure/msal-browser";

interface Config extends Configuration {
    auth: any;
    cache: any;
    apiURL: string | undefined;
    apimKey: string | undefined;
    scope: (string | undefined)[]
}

export const msalConfig: Config = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID,
        authority: process.env.REACT_APP_AUTHORITY,
        redirectUri: process.env.REACT_APP_REDIRECT_URI,
        postLogoutRedirectUri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
        validateAuthority: false,
        knownAuthorities: [process.env.REACT_APP_KNOWN_AUTHORITY],
    },
    cache: {
        cacheLocation: process.env.REACT_APP_SESSION_STORAGE,
        storeAuthStateInCookie: "false",
    },
    apiURL: process.env.REACT_APP_API_URL,
    apimKey: process.env.REACT_APP_API_MKEY,
    scope: [process.env.REACT_APP_SCOPE1, process.env.REACT_APP_SCOPE2],
};

export const pca = new PublicClientApplication(msalConfig);

// Initiate the password reset flow if the user has forgotten their password.
pca.addEventCallback(async (message) => {
    if (message.eventType === EventType.LOGIN_FAILURE) {
        if (message.error.message.includes("AADB2C90118")) { // The user has forgotten their password.
            await pca.loginRedirect({
                authority: process.env.REACT_APP_RESET_PASSWORD_AUTHORITY,
                scopes: ["openid", "profile"],
            })
        }
    }
});