import axios from "axios";
import {jwtDecode, JwtPayload} from "jwt-decode";

const BASE_URL = process.env.REACT_APP_API_URL
const APIM_KEY = process.env.REACT_APP_API_MKEY
const APP_ID = process.env.REACT_APP_SCOPE_APP_ID

export async function fetchTokenWithCredentials({username, password}, shortTimeout: boolean): Promise<{
    jwt: JwtPayload,
    accessToken: string
}> {
    try {
        const response = await axios({
            method: "POST",
            url: `${BASE_URL}/onboardingV2/v1/LobbyScreenAuth/Authenticate`,
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": APIM_KEY
            },
            data: {
                // For both dev and production
                applicationId: APP_ID,
                username: decodeURIComponent(username),
                password: decodeURIComponent(password)
            },
            // First timeout is a bit shorter
            timeout: shortTimeout ? 6_500 : 45_000,
        });

        if (response.status === 200) {
            return {
                jwt: jwtDecode(response.data),
                accessToken: response.data
            };
        } else {
            console.error('Token fetch returned non-200 response. Failed to login.', response);
        }
    } catch (e) {
        console.error('Something went wrong while fetching token:', e);
        throw e;
    }

    throw new Error('Failed to fetch token. See previous error logs for more information.');
}