import {StrictMode} from 'react';
import ReactDOM from 'react-dom/client';
import {MSAAPP} from './app';
import {appInsightsEnabled, appInsightsReactPlugin} from "./appInsights";
import {AppInsightsErrorBoundary} from "@microsoft/applicationinsights-react-js";
import {ErrorFallback} from "./app/ErrorFallback";
import {ErrorBoundary} from "react-error-boundary";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const ErrorBoundaryWrapper = ({children}) => {
    if (appInsightsEnabled) {
        return (
            <AppInsightsErrorBoundary
                appInsights={appInsightsReactPlugin}
                onError={() => <ErrorFallback/>}
            >
                {children}
            </AppInsightsErrorBoundary>
        );
    }

    return (
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onError={(error, errorLog) => console.log({error, errorLog})}
        >
            {children}
        </ErrorBoundary>
    );
};

root.render(
    <ErrorBoundaryWrapper>
        <StrictMode>
            <MSAAPP/>
        </StrictMode>
    </ErrorBoundaryWrapper>
);
