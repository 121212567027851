import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { PlantImageResponse } from "./plantScreenApi.types";
import {getToken} from "../../shared/utils/authUtils";

const BASE_API_URL = process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : "https://skifryfpel-dev-apimngment.azure-api.net/";

const sidemApiUrl = `SkyfriSidemApi/v1`
const lobbyApiUrl = `SkyfriSidemApi/v1/LobbyScreen`


export const plantScreenApi = createApi({
    reducerPath: "plantScreenApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_API_URL}`,
        prepareHeaders: async (headers) => {
            let token = await getToken();
            headers.set("authorization", `Bearer ${token}`);
            headers.set(
                "Ocp-Apim-Subscription-Key",
                process.env.REACT_APP_API_MKEY
            );
            headers.set("Content-Type", "text/json");
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getPlantProfileInfoLobby: builder.query({
            query: ({ portfolioId, plantId }) => `${lobbyApiUrl}/GetClientInfo?portfolioName=${portfolioId}&plantId=${plantId}`,
            keepUnusedDataFor: 4000
        }),
        getAcPowerIrrPlant: builder.query({
            query: ({ plantId }) => `${lobbyApiUrl}/GetAcPowerVsIrrad?plantId=${plantId}`,
            keepUnusedDataFor: 4000
        }),
        getAcPowerIrrKpiPlant: builder.query({
            query: ({ portfolioId, plantId }) => `${lobbyApiUrl}/GetPlantAcPowerIrradiance?portfolioName=${portfolioId}&plantId=${plantId}`,
            keepUnusedDataFor: 4000
        }),
        getPlantLocation: builder.query({
            query: ({ plantId }) => `${lobbyApiUrl}/GetPlantLocation?plantId=${plantId}`,
            keepUnusedDataFor: 4000
        }),
        getGenDataPlantLobby: builder.query({
            query: ({ portfolioId, plantId }) => `${lobbyApiUrl}/GetGenData?portfolioName=${portfolioId}&plantId=${plantId}`,
            keepUnusedDataFor: 4000
        }),
        getGenDataPlantAmsLobby: builder.query({
            query: ({ portfolioId, plantId, fromDate, toDate }) => `${lobbyApiUrl}/GetGenData?portfolioName=${portfolioId}&plantId=${plantId}&fromDate=${fromDate}&toDate=${toDate}`,
            keepUnusedDataFor: 4000
        }),
        getGraphDataPlantAmsLobby: builder.query({
            query: ({ plantId, fromDate, toDate }) => `${lobbyApiUrl}/GetGenDataDaily?fromDate=${fromDate}&toDate=${toDate}&plantId=${plantId}`,
            keepUnusedDataFor: 4000
        }),
        getCommissionDate: builder.query({
            query: ({ plantId }) => `${lobbyApiUrl}/GetCommissionDate?plantId=${plantId}`,
            keepUnusedDataFor: 4000
        }),
        getPlantImage: builder.query({
            query: (plantId) => ({
                url: `${sidemApiUrl}/LobbyScreenPlantImages/GetLobbyScreenPlantImage?plantId=${plantId}`,
                keepUnusedDataFor: 60*60*1000 // 1 hour
            }),
            transformResponse: (response: Array<PlantImageResponse>) => {
                const siteImage = response?.[0]?.plantImage?.fileContents;
                const imageType = response?.[0]?.plantImage?.contentType;

                if (siteImage && imageType) {
                    const plantImage = `data:${imageType};base64,${siteImage}`;
                    return plantImage;
                }
                return null;
            },
        }),
        getPlantMonthlyData: builder.query({
            query: ({ plantId }) => `${lobbyApiUrl}/GetGenDataMonthlyYearlyTVLobby?plantId=${plantId}`,
            keepUnusedDataFor: 4000
        }),
        getPlantYearlyData: builder.query({
            query: ({ plantId }) => `${lobbyApiUrl}/GetGenDataYearlyTVLobby?plantId=${plantId}`,
            keepUnusedDataFor: 4000
        }),
    }),
});

export const {
    useGetPlantProfileInfoLobbyQuery,
    useGetAcPowerIrrPlantQuery,
    useGetAcPowerIrrKpiPlantQuery,
    useGetPlantLocationQuery,
    useGetGenDataPlantLobbyQuery,
    useGetGraphDataPlantAmsLobbyQuery,
    useGetGenDataPlantAmsLobbyQuery,
    useGetCommissionDateQuery,
    useGetPlantImageQuery,
    useGetPlantMonthlyDataQuery,
    useGetPlantYearlyDataQuery,
} = plantScreenApi;
