import {ReactPlugin} from "@microsoft/applicationinsights-react-js";
import {ApplicationInsights} from "@microsoft/applicationinsights-web";
import {createBrowserHistory} from "history";

export const appInsightsReactPlugin = new ReactPlugin();
export const appInsightsEnabled = !!process.env.REACT_APP_APPINSIGHTS_CONNECTION_STRING;
export const appInsights = new ApplicationInsights({
    config: {
        connectionString: process.env.REACT_APP_APPINSIGHTS_CONNECTION_STRING,
        extensions: [new ReactPlugin()],
        enableAutoRouteTracking: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        enableCorsCorrelation: true,
        enableAjaxErrorStatusText: true,
        extensionConfig: {
            [appInsightsReactPlugin.identifier]: {history: createBrowserHistory()},
        },
    },
});

if (appInsightsEnabled) {
    appInsights.loadAppInsights();
}
