/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sharedStateSelector } from "../../../../features/sharedState/sharedSlice";
import { autoRefreshApiTime } from "../../../../shared/utils/utils";
import { getLobbyLogo } from "../../../../features/header/headerActions";
import { getTenantColor, getUserName } from "../../../../features/sharedActions/sharedAction";
import { useGetLobbyScreenColorsQuery } from "../../../../features/sharedActions/sharedApi";
import {jwtDecode} from "jwt-decode";
import {getToken} from "../../../../shared/utils/authUtils";

interface Data {
    logo: any;
    isError?: boolean;
    clientName?: string;
    logOut?: () => Promise<void>;
    userName?: string;
}

export const useHeaderWidget = (): Data => {
    const [userId, setUserId] = useState<string>(null);
    const [logo, setLogo] = useState<any>(null);
    const { data: colorsApi, isError, refetch } = useGetLobbyScreenColorsQuery({ userId: userId }, { skip: !userId });
    const { clientName, userName, plantIds } = useSelector(sharedStateSelector)
    const dispatch = useDispatch()

    const setUserName = async () => {
        const token = await getToken();
        const jwt = jwtDecode(token)
        setUserId(jwt["extension_userid"])
        dispatch(getUserName(jwt["name"]))
    }

    useEffect(() => {
        setUserName()
    }, []);

    useEffect(() => {
        if (!plantIds) return
        getLogo()
    }, [plantIds]);

    useEffect(() => {
        if (isError || !colorsApi) return
        dispatch(getTenantColor(colorsApi))
    }, [colorsApi]);

    useEffect(() => {
        if (!isError) return
        const myInterval = autoRefreshApiTime(() => refetch(), .50);
        return () => clearInterval(myInterval);
    }, [isError]);

    const getLogo = async () => {
        const resp: any = await getLobbyLogo("PRIMARY")
        if (resp?.status === 400 || resp?.status === 404 || resp?.status === 204 || resp?.status === 500 || !resp?.data?.size)
            setLogo(null)
        else {
            const url = URL.createObjectURL(resp?.data);
            setLogo(url)
        }
    }
    useEffect(() => {
        if (logo) return
        const myInterval = autoRefreshApiTime(() => getLogo(), 1);
        return () => clearInterval(myInterval);
    }, [logo]);

    return {
        logo,
        clientName,
        userName
    };
};
