import axios from "axios";
import {getToken} from "../shared/utils/authUtils";

const BASE_API_URL = process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : "https://skifryfpel-dev-apimngment.azure-api.net/";

const prepareHeaders = async () => {
    const token = await getToken();
    return {
        authorization: "Bearer " + token,
        "Ocp-Apim-Subscription-Key": process.env.REACT_APP_API_MKEY,
        "Content-Type": "text/json",
    }
}

export const axGet = async (url: string) => {
    try {
        const res = await axios({
            method: "get",
            url: BASE_API_URL + url,
            headers: await prepareHeaders()
        });
        return res.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};