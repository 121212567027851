/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useGetPlantProfileInfoQuery } from "../../features/sharedActions/sharedApi";
import { useDispatch } from "react-redux";
import { getAmsMeter, getNumberOfPlants, getPlantIds } from "../../features/sharedActions/sharedAction";
import { checkNull } from "../../shared/utils/utils";
import { getLobbyLogo } from "../../features/header/headerActions";
interface Data {
    nextScreenInfo: () => void
    setScreenType: () => void;
    date?: string;
    nextPlantInfo: () => void;
    secondaryLogo?: any;
}
export const useFooter = (): Data => {
    const dispatch = useDispatch();
    const [url, setUrl] = useSearchParams();

    const plantIdUrl = url.get("plantId")
    const portfolioIdUrl = url.get("portfolioId")

    const { data: plantProfileInfoData } = useGetPlantProfileInfoQuery();

    const navigate = useNavigate();
    const path = window.location.pathname;

    const [nextScreen, setNextScreen] = useState('');
    const [currentPlantIndex, setCurrentPlantIndex] = useState(null);
    const [date, setDate] = useState('');
    const [secondaryLogo, setSecondaryLogo] = useState<any>(null);
    const [plantProfileInfo, setPlantProfileInfo] = useState(null);

    useEffect(() => {
        if (!plantProfileInfoData) return
        setPlantProfileInfo(plantProfileInfoData.filter(plant => plant.energyPageEnabled || plant.powerPageEnabled || plant.siteMonthlyPageEnabled || plant.siteOverviewPageEnabled || plant.siteYearlyPageEnabled))
    }, [plantProfileInfoData])

    useEffect(() => {
        dispatch(getNumberOfPlants(plantProfileInfo?.length));
    }, [plantProfileInfo])

    const setDateForNextScreen = (url: string, index: number) => {
        const profileInfo = plantProfileInfo?.[index];

        if (!profileInfo) {
            return "Last 12 months";  // Default return if profileInfo is not available
        }

        const { energyPageEnabled,
            siteOverviewPageEnabled,
            powerPageEnabled,
            powerPageVersion,
            energyPageVersion,
            siteMonthlyPageEnabled,
            siteMonthlyPageVersion,
            siteYearlyPageEnabled,
            siteYearlyPageVersion
        } = profileInfo;

        if (url === '/') {
            if (energyPageEnabled) {
                return "30-Day Production";
            }
            if (siteOverviewPageEnabled) {
                return "Overview";
            }
            if (powerPageEnabled) {
                return "Today's Power";
            }
            if (siteMonthlyPageEnabled) {
                return "Monthly production";
            }
            if (siteYearlyPageEnabled) {
                return "Total energy production";
            }
            return "Last 12 months";
        }

        if (url === '/plant-screen-overview') {
            if (energyPageEnabled) {
                return "30-Day Production";
            }
            if (powerPageEnabled) {
                return "Today's Power";
            }
            if (siteMonthlyPageEnabled) {
                return "Monthly production";
            }
            if (siteYearlyPageEnabled) {
                return "Total energy production";
            }
            return "Last 12 months";
        }

        if (url === `/plant-screen-ams-v${energyPageVersion}`) {
            if (powerPageEnabled) {
                return "Today's Power";
            }
            if (siteMonthlyPageEnabled) {
                return "Monthly production";
            }
            if (siteYearlyPageEnabled) {
                return "Total energy production";
            }
            return "Last 12 months";
        }

        if (url === `/plant-screen-v${powerPageVersion}`) {
            if (siteMonthlyPageEnabled) {
                return "Monthly production";
            }
            if (siteYearlyPageEnabled) {
                return "Total energy production";
            }
            return "Last 12 months";
        }

        if (url === `/plant-monthly-v${siteMonthlyPageVersion}`) {
            if (siteYearlyPageEnabled) {
                return "Total energy production";
            }
            return "Last 12 months";
        }

        if (url === `/plant-yearly-v${siteYearlyPageVersion}`) {
            return "Last 12 months";
        }

        return "Last 12 months";
    };

    useEffect(() => {
        if (!plantProfileInfo || !plantProfileInfo?.length) return
        // Find the index of the plant with the given ID in the response data
        const index = plantProfileInfo?.findIndex(plant => parseInt(plant?.plantId) === parseInt(plantIdUrl));
        if (index !== -1) {
            setCurrentPlantIndex(index);
        } else {
            setCurrentPlantIndex(0)
            // Handle the case where the ID from the URL doesn't match any plant in the response
            console.log("Plant not found!");
        }
    }, [plantIdUrl, plantProfileInfo]);

    useEffect(() => {
        if (!plantProfileInfo || currentPlantIndex === null) return
        const plantsInfo = plantProfileInfo?.[currentPlantIndex]

        if (!plantIdUrl || !portfolioIdUrl) {
            setUrl(
                (prev) => {
                    prev.set("portfolioId", plantProfileInfo?.[currentPlantIndex]?.portfolioName);
                    prev.set("plantId", plantProfileInfo?.[currentPlantIndex]?.plantId);
                    prev.set("version", checkNull(plantProfileInfo?.[currentPlantIndex]?.aggregatePageVersion));
                    return prev;
                },
                { replace: true }
            );
        }
        setNextScreenBasedOnPath(path, plantsInfo)
    }, [plantProfileInfo, currentPlantIndex, navigate])

    const setNextScreenBasedOnPath = (path: string, plantsInfo: any) => {
        if (!plantsInfo) return;

        const { powerPageVersion,
            energyPageEnabled,
            powerPageEnabled,
            energyPageVersion,
            siteOverviewPageEnabled,
            siteMonthlyPageEnabled,
            siteMonthlyPageVersion,
            siteYearlyPageEnabled,
            siteYearlyPageVersion
        } = plantsInfo;

        if (path === "/") {
            if (siteOverviewPageEnabled) {
                setNextScreen('plant-overview');
                return;
            }
            if (energyPageEnabled) {
                setNextScreen('plant-ams');
                return;
            }
            if (powerPageEnabled) {
                setNextScreen('plant');
                return;
            }
            if (siteMonthlyPageEnabled) {
                setNextScreen('siteMonthly');
                return;
            }
            if (siteYearlyPageEnabled) {
                setNextScreen('siteYearly');
                return;
            }
            else {
                setNextScreen('aggregate');
                return;
            }
        }

        if (path === `/plant-screen-overview`) {
            if (energyPageEnabled) {
                setNextScreen('plant-ams');
                return;
            }
            if (powerPageEnabled) {
                setNextScreen('plant');
                return;
            }
            if (siteMonthlyPageEnabled) {
                setNextScreen('siteMonthly');
                return;
            }
            if (siteYearlyPageEnabled) {
                setNextScreen('siteYearly');
                return;
            }
            setNextScreen('aggregate');
            return;
        }

        if (path === `/plant-screen-ams-v${checkNull(energyPageVersion)}`) {
            if (powerPageEnabled) {
                setNextScreen('plant');
                return;
            }
            if (siteMonthlyPageEnabled) {
                setNextScreen('siteMonthly');
                return;
            }
            if (siteYearlyPageEnabled) {
                setNextScreen('siteYearly');
                return;
            }
            else {
                setNextScreen('aggregate');
                return;
            }
        }

        if (path === `/plant-screen-v${checkNull(powerPageVersion)}`) {
            if (siteMonthlyPageEnabled) {
                setNextScreen('siteMonthly');
                return;
            }
            if (siteYearlyPageEnabled) {
                setNextScreen('siteYearly');
                return;
            }
            else {
                setNextScreen('aggregate');
                return;
            }
        }

        if (path === `/plant-monthly-v${checkNull(siteMonthlyPageVersion)}`) {
            if (siteYearlyPageEnabled) {
                setNextScreen('siteYearly');
                return;
            }
            else {
                setNextScreen('aggregate');
                return;
            }
        }

        if (path === `/plant-yearly-v${checkNull(siteYearlyPageVersion)}`) {
            setNextScreen('aggregate');
        }
    };

    useEffect(() => {
        if (!plantProfileInfo || !plantProfileInfo?.length) return
        dispatch(getPlantIds(plantProfileInfo?.map((item) => item?.plantId).join(',') || ''));
        dispatch(getAmsMeter(plantProfileInfo?.every((item) => item?.amsMeter === "1")));
        getSecondaryLogo()
    }, [plantProfileInfo]);

    const getSecondaryLogo = async () => {
        const resp: any = await getLobbyLogo("SECONDARY")
        if (resp?.status === 400 || resp?.status === 404 || resp?.status === 204 || resp?.status === 500 || !resp?.data?.size || resp?.data?.type === "image/png")
            setSecondaryLogo(null)
        else {
            const url = URL.createObjectURL(resp?.data);
            setSecondaryLogo(url)
        }
    }

    useEffect(() => {
        if (!plantProfileInfo || !plantProfileInfo?.length || currentPlantIndex === null) return
        setDate(setDateForNextScreen(path, currentPlantIndex))
    }, [window.location.pathname, plantProfileInfo, currentPlantIndex])

    const nextPlant = () => {
        if (!plantProfileInfo || !plantProfileInfo?.length) return
        setCurrentPlantIndex((prevIndex) => (prevIndex + 1) % plantProfileInfo?.length);
    };

    // Makes sure to keep passwordless login params in the URL when navigating
    const navigateWithParams = (path: string) => {
        const searchParams = new URLSearchParams(window.location.search);
        const u = searchParams.get('u');
        const p = searchParams.get('p');
        const newPath = new URL(path, window.location.origin);
        if (u) newPath.searchParams.set('u', u);
        if (p) newPath.searchParams.set('p', p);
        navigate(newPath.pathname + newPath.search);
    };

    const setScreenType = () => {
        // energy page is ams page
        // power page is ac/irr page
        // aggregate page is overview page

        if (!plantProfileInfo || !plantProfileInfo?.length) return;

        const currentPlantInfo = plantProfileInfo?.[currentPlantIndex];

        const nextPlantInfo = plantProfileInfo?.[(currentPlantIndex + 1) % plantProfileInfo?.length];

        if (!plantProfileInfo || !plantProfileInfo?.length) return

        if (nextScreen === 'aggregate') {
            if (nextPlantInfo?.siteOverviewPageEnabled)
                setNextScreen('plant-overview');
            if (nextPlantInfo?.energyPageEnabled)
                setNextScreen('plant-ams');
            if (nextPlantInfo?.powerPageEnabled)
                setNextScreen('plant');
            if (nextPlantInfo?.siteMonthlyPageEnabled)
                setNextScreen('siteMonthly');
            if (nextPlantInfo?.siteYearlyPageEnabled)
                setNextScreen('siteYearly');

            navigateWithParams(`/?portfolioId=${nextPlantInfo?.portfolioName}&plantId=${nextPlantInfo?.plantId}&version=${checkNull(nextPlantInfo?.aggregatePageVersion)}`);
            nextPlant();
        } else {
            if (currentPlantInfo?.siteOverviewPageEnabled && nextScreen === 'plant-overview') {
                navigateWithParams(`/plant-screen-overview?portfolioId=${currentPlantInfo?.portfolioName}&plantId=${currentPlantInfo?.plantId}&plantName=${currentPlantInfo?.plantName}`);
                if (currentPlantInfo?.energyPageEnabled)
                    setNextScreen('plant-ams');
                else if (currentPlantInfo?.powerPageEnabled)
                    setNextScreen('plant');
                else if (currentPlantInfo?.siteMonthlyPageEnabled)
                    setNextScreen('siteMonthly');
                else if (currentPlantInfo?.siteYearlyPageEnabled)
                    setNextScreen('siteYearly');
                else
                    setNextScreen('aggregate');
            }
            else if (currentPlantInfo?.energyPageEnabled && nextScreen === 'plant-ams') {
                navigateWithParams(`/plant-screen-ams-v${checkNull(currentPlantInfo?.energyPageVersion)}?portfolioId=${currentPlantInfo?.portfolioName}&plantId=${currentPlantInfo?.plantId}&plantName=${currentPlantInfo?.plantName}`);
                if (currentPlantInfo?.powerPageEnabled)
                    setNextScreen('plant');
                else if (currentPlantInfo?.siteMonthlyPageEnabled)
                    setNextScreen('siteMonthly');
                else if (currentPlantInfo?.siteYearlyPageEnabled)
                    setNextScreen('siteYearly');
                else
                    setNextScreen('aggregate');
            }
            else if (currentPlantInfo?.powerPageEnabled && nextScreen === 'plant') {
                navigateWithParams(`/plant-screen-v${checkNull(currentPlantInfo?.powerPageVersion)}?portfolioId=${currentPlantInfo?.portfolioName}&plantId=${currentPlantInfo?.plantId}&plantName=${currentPlantInfo?.plantName}`);
                if (currentPlantInfo?.siteMonthlyPageEnabled) {
                    setNextScreen('monthly');
                }
                else if (currentPlantInfo?.siteYearlyPageEnabled) {
                    setNextScreen('siteYearly');
                }
                else {
                    setNextScreen('aggregate');
                }
            }
            else if (currentPlantInfo?.siteMonthlyPageEnabled && nextScreen === 'siteMonthly') {
                navigateWithParams(`/plant-monthly-v${checkNull(currentPlantInfo?.siteMonthlyPageVersion)}?portfolioId=${currentPlantInfo?.portfolioName}&plantId=${currentPlantInfo?.plantId}&plantName=${currentPlantInfo?.plantName}`);
                if (currentPlantInfo?.siteYearlyPageEnabled) {
                    setNextScreen('siteYearly');
                }
                else {
                    setNextScreen('aggregate');
                }
            }
            else {
                if (currentPlantInfo?.siteYearlyPageEnabled) {
                    navigateWithParams(`/plant-yearly-v${checkNull(currentPlantInfo?.siteYearlyPageVersion)}?portfolioId=${currentPlantInfo?.portfolioName}&plantId=${currentPlantInfo?.plantId}&plantName=${currentPlantInfo?.plantName}`);
                    setNextScreen('aggregate');
                }
                else {
                    navigateWithParams(`/?portfolioId=${nextPlantInfo?.portfolioName}&plantId=${nextPlantInfo?.plantId}&version=${checkNull(nextPlantInfo?.aggregatePageVersion)}`);
                    nextPlant();
                }
            }
        }
    };

    const checkNextScreenInfo = () => {
        if (nextScreen === 'aggregate') {
            return `Overview - ${date}`;
        } if (nextScreen === 'plant-overview') {
            return `- Overview`;
        } if (nextScreen === 'plant-ams') {
            return `- ${date}`;
        } if (nextScreen === 'plant') {
            return `- ${date}`;
        } if (nextScreen === 'siteMonthly') {
            return `- Monthly production`;
        } if (nextScreen === 'siteYearly') {
            return `- Total energy production`;
        }
    }

    const checkNextPlantInfo = () => {
        if (nextScreen === 'aggregate') {
            return '';
        } if (nextScreen === 'plant-overview') {
            return `${plantProfileInfo[(currentPlantIndex) % plantProfileInfo.length]?.plantName}`;
        } if (nextScreen === 'plant-ams') {
            return `${plantProfileInfo[(currentPlantIndex) % plantProfileInfo.length]?.plantName}`;
        } if (nextScreen === 'plant') {
            return `${plantProfileInfo[(currentPlantIndex) % plantProfileInfo.length]?.plantName}`;
        } if (nextScreen === 'siteMonthly') {
            return `${plantProfileInfo[(currentPlantIndex) % plantProfileInfo.length]?.plantName}`;
        } if (nextScreen === 'siteYearly') {
            return `${plantProfileInfo[(currentPlantIndex) % plantProfileInfo.length]?.plantName}`;
        }
    }

    const nextScreenInfo = () => {
        if (!plantProfileInfo || !plantProfileInfo.length) return
        return checkNextScreenInfo();
    };

    const nextPlantInfo = () => {
        if (!plantProfileInfo || !plantProfileInfo.length) return

        return checkNextPlantInfo();
    };

    return {
        nextScreenInfo,
        setScreenType,
        date,
        nextPlantInfo,
        secondaryLogo
    };
};
