import {fetchTokenWithCredentials} from "./authToken";
import {pca} from "../../msal";
import {jwtDecode} from "jwt-decode";
import {Roles, RolesMap} from "../interfaces";
import {SIDEM_SCOPE} from "./scope";
import {appInsights, appInsightsEnabled} from "../../appInsights";

type TokenCache = {
    [username: string]: {
        token: string,
        // Expiry time in ISO format
        expiry: string
    }
}

function getTokenCacheFromLocalStorage(): TokenCache {
    const cache = localStorage.getItem('tokenCache');
    return cache ? JSON.parse(cache) : {};
}

function saveTokenCacheToLocalStorage(tokenCache: TokenCache) {
    localStorage.setItem('tokenCache', JSON.stringify(tokenCache));
}

function saveTokenToLocalStorage(username: string, token: string, expiry: Date) {
    const tokenCache = getTokenCacheFromLocalStorage();
    tokenCache[username] = {token, expiry: expiry.toISOString()};
    saveTokenCacheToLocalStorage(tokenCache);
}

function getTokenFromLocalStorage(username: string): string | null {
    const tokenCache = getTokenCacheFromLocalStorage();
    const cachedToken = tokenCache[username];
    return cachedToken && new Date() < new Date(cachedToken.expiry) ? cachedToken.token : null;
}

function getTokenExpiresFromLocalStorage(username: string): Date | null {
    const tokenCache = getTokenCacheFromLocalStorage();
    const cachedToken = tokenCache[username];
    return cachedToken ? new Date(cachedToken.expiry) : null;
}

function resetAuthInLocalStorage() {
    console.debug("Clearing local storage.")
    localStorage.clear();
}

export function getCredentialsFromUrl(): { username: string, password: string } {
    const urlParams = new URLSearchParams(window.location.search);
    return {
        username: urlParams.get('u'),
        password: urlParams.get('p')
    }
}

async function getPasswordLessToken(shortTimeout = false) {
    const credentials = getCredentialsFromUrl();
    const {username} = credentials;
    let token = getTokenFromLocalStorage(username);
    const expiry = getTokenExpiresFromLocalStorage(username);

    if (!token || !expiry || new Date() > expiry) {
        console.log("Fetching new token with passwordless credentials");
        const {jwt, accessToken} = await fetchTokenWithCredentials(credentials, shortTimeout);
        const newExpiry = new Date(jwt.exp * 1000 - 1000 * 60 * 5);
        saveTokenToLocalStorage(username, accessToken, newExpiry);
        token = accessToken;
    }

    return token;
}

export function isUsingPasswordlessAuth() {
    return window.location.search.includes('u=') && window.location.search.includes('p=');
}

// FIXME: this is a temporary workaround for a sporadically failing passwordless login
export async function getPasswordlessWithRetry() {
    for (let attempt = 1; attempt <= 5; attempt++) {
        try {
            // Do a short timeout on the first request
            return await getPasswordLessToken(attempt === 1);
        } catch (error) {
            if (appInsightsEnabled) {
                appInsights.trackException(error, {username: getCredentialsFromUrl().username, attempt})
            }
            if (attempt === 5) {
                throw error; // Rethrow the error if it's the last attempt
            }
            console.error(`Login attempt ${attempt} failed: ${error.message}`);
        }
    }
}

export async function initializePasswordless() {
    const urlParams = new URLSearchParams(window.location.search);
    const resetAuth = urlParams.get('resetAuth');

    // For troubleshooting, allow to reset localstorage auth
    if (resetAuth) {
        resetAuthInLocalStorage();
    }

    await getPasswordlessWithRetry();
}

export async function getToken() {
    if (isUsingPasswordlessAuth()) {
        return getPasswordLessToken();
    } else {
        const token = await pca.acquireTokenSilent({
            scopes: ["profile", ...SIDEM_SCOPE],
            account: pca.getAllAccounts()[0]
        });
        return token.accessToken
    }
}

export async function getRoles(): Promise<RolesMap> {
    const token = await getToken();
    const roles = {
        [Roles.MONITORING]: false,
        [Roles.SIDEM]: false,
        [Roles.OPERATIONS]: false,
        [Roles.CMMS]: false,
        [Roles.BILLING]: false,
        [Roles.ONBOARDING]: false,
        [Roles.COMMERCIAL]: false,
        [Roles.ANALYTICS]: false,
        [Roles.SKYFRIDEMO]: false,
    };

    const rolesInToken = jwtDecode(token)["extension_roles"] ?? '';
    rolesInToken.split(",").forEach((item: Roles) => {
        roles[item] = true;
    });

    return roles;
}

export function logout() {
    resetAuthInLocalStorage();

    if (!isUsingPasswordlessAuth()) {
        pca.logoutRedirect()
    }
}